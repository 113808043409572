import React, { Component } from 'react'
import styles from './privacyhead.module.scss'



class PageHead extends Component {
  render() {
    return (
      <div className={styles.pageHead}>
        <div className={styles.container}>
           <div className={styles.content}>
            <div className={styles.text}>
              <h1>
                {this.props.head}
              </h1>
              <h2>
               {this.props.text}
              </h2>
            </div>
        </div>
      </div>
      </div>
    )
  }
}

export default PageHead