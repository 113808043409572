import React, { Component } from 'react';
import styles from './privacytext.module.scss'


class PrivacyText extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.head}>
          <h1>
            Privacy Policy 
          </h1>
          <p>
            Updated 26 September, 2019
          </p>
        </div>

        <section>
          <h2>Overview</h2>
          <p>iQubeLabs is committed to building innovative solutions. The privacy policy stated below encompasses the following premises:</p>
          <ol>
            <li>Types of Collected Data</li>
            <li>Purpose of data collected</li>
            <li>Data sharing</li>
            <li>Storage of data</li>
            <li>Timeframe for database</li>
          </ol>
        </section>

        <section>
          <h2>Types of Data Collected</h2>
          <p>We collect some information such as:</p>
          <ol>
            <li>Pages visited</li>
            <li> Clickstream data</li>
            <li> Other browsing information</li>
            <li>Personal Identifiable Information (PII)</li>
          </ol>
          <p>*iQube website does not use cookies. Cookies allow a website to recognize a user’s computer, not the individual user, and keep track of the user's preferences. This website uses third-party technologies which provide useful features and services. Most browsers are set to accept cookies by default. You can always set your browser to refuse cookies, but you may not be able to access all the features of our site.*</p>
          <article>
            <h4>
              Pages Visited
            </h4>
            <p>
              Page visits by the user(s) are one of the metrics collected by the website.
            </p>
          </article>
          <article>
            <h4>
              Clickstream Data
            </h4>
            <p>
              This metric is used to track the user's activity on the internet which encompasses page visits, the order of page visits and time spent on web pages.
            </p>
          </article>

          <article>
            <h4>
              Other Browsing Information
            </h4>
            <p>
              Relevant data that can help improve our website and services are also collated via the contact page.
            </p>
          </article>

         <article>
          <h4>Personal Identifiable Information (PII)</h4>
          <p>
            Personal data required through filling out any form and submitting will only be used for the information provided to respond to your request. Bio-data may be required on some of our pages such as the career portal.
          </p>
          </article>

        </section>  

        <section>
          <article>
            <h2>Purpose of data collected</h2>
            <p>Data collected will be used for the request submitted. Also, data will be used for performance analysis and engagement on our platform.</p>
          </article>
          <article>
            <h2>Data Sharing</h2>
            <p>User’s information would only be shared with a third party under the condition of a written consent by the user.</p>
          </article>
          <article>
            <h2>Storage of Data</h2>
            <p>
              Data is stored on our server. iQubeLabs takes technical and physical measures to protect information collected on this website. However, no website or database is completely hacker-proof.  Users are responsible for protecting their personal information from unauthorised disclosure.
            </p>
          </article>

          <article>
            <h2>How long we keep you data?</h2>
            <p>Users data will be kept in our database for the timeframe of 1 year of inactivity after which the information will be completely deleted from our records and database.
              Users who engage with the platform would have their time count from the last moment of activity on the website after which their data will be deleted from the database.
              <br></br>
              An llustration of the timeframe policy below:
              <em>If user A provides personal data on the 30th of September,2019 on the career portal. The data of the user A will be deleted from the database by 30th of September,2020</em>
              </p>
          </article>
        </section>
      </div>
    )
  }
}

export default PrivacyText
