import React from 'react';
import Layout from '../components/layout';
import PrivacyText from '../components/PrivacyText/PrivacyText'
import PrivacyHead from '../components/PrivacyHead/PrivacyHead'

function Privacy() {
  return (
    <Layout>
      <PrivacyHead
        head="Privacy"
      />
      <PrivacyText />
    </Layout>
  )
}

export default Privacy
